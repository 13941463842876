import { SelectActions, TableBasis } from 'ui';
import { Wrapper } from './ServicesListPage.styled';
import { SERVICES_TABLE } from 'constants/tablesHead';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { retrieveTools } from 'reduxStore/createProfile/createProfileOperations';
import { getTools } from 'reduxStore/createProfile/createProfileSelectors';

const ServicesListPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tools = useAppSelector(getTools);

  useEffect(() => {
    dispatch(retrieveTools(null));
  }, [dispatch]);

  const servicesFormatted = Array.isArray(tools)
    ? tools.map(tool => {
        const connectionStatus =
          (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
          (tool.clients_count !== null && tool.clients_count !== 0)
            ? 'Connected'
            : 'Disconnected';
        return {
          service_name: tool.name,
          client_count: tool.clients_count,
          devices: tool.devices_count,
          status: tool.status?.name,
          is_connected: connectionStatus,
        };
      })
    : [];

  const handleAddIntegration = () => {
    navigate(ROUTES.ADD_SERVICE);
  };

  const handleDeleteIntegration = () => {
    navigate(ROUTES.DELETE_INTEGRATION);
  };

  return (
    <Wrapper>
      <TableBasis
        tableData={servicesFormatted}
        columns={SERVICES_TABLE}
        title="Services"
        icon="edit"
        action={
          <SelectActions
            name="Integrations"
            onAdd={handleAddIntegration}
            onDelete={handleDeleteIntegration}
          />
        }
      ></TableBasis>
    </Wrapper>
  );
};

export default ServicesListPage;
