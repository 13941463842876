import React, { useEffect, useState } from 'react';
import {
  StyledActionBlock,
  StyledTableContainer,
  StyledTypography,
  StyledWrapperTitle,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  WrapperTable,
} from './TableBasis.styled';
import { TableBody, Table, IconButton, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconEdit, IconGlass } from 'ui';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IAdminType, ITableColumn, ITableRow } from 'types';
import { IUserType } from 'types/organizations.type';
import { getFormatCellValue } from 'utils';

interface ITableBasisProps extends IAdminType {
  maxheight?: string;
  title?: string;
  action?: React.JSX.Element;
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  isCheckbox?: boolean;
  isWarning?: boolean;
  enabledSwitchFlag?: (row: ITableRow) => boolean;
  onClick?: (item: ITableRow) => void;
  onClickShowMore?: () => void;
  onChangeSwitch?: (item: IUserType) => void;
  onCheckboxChange?: (selectedIndexes: number[]) => void;
  tableData: ITableRow[];
  columns: ITableColumn[];
  selectedIndexes?: number[];
}

const TableBasis = ({
  maxheight,
  title,
  action,
  type,
  icon,
  isCheckbox,
  isWarning,
  onClick,
  onCheckboxChange,
  tableData,
  columns,
  selectedIndexes,
}: ITableBasisProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    if (!selectedIndexes || !selectedIndexes.length) return;

    setSelectedRows(selectedIndexes);
  }, [selectedIndexes]);

  const cellValue = (rowData: ITableRow, columnData: ITableColumn) => {
    const value = rowData[columnData.key]?.toString() || '-';
    return getFormatCellValue(value, columnData.format);
  };

  const handleSelectAllCheckbox = () => {
    if (selectedRows.length === tableData?.length) {
      setSelectedRows([]);
      onCheckboxChange?.([]);
    } else {
      const allRows = tableData ? tableData.map((_, index) => index) : [];
      setSelectedRows(allRows);
      onCheckboxChange?.(allRows);
    }
  };

  const handleCheckboxClick = (index: number) => {
    const newSelected = selectedRows.includes(index)
      ? selectedRows.filter(i => i !== index)
      : [...selectedRows, index];

    setSelectedRows(newSelected);
    onCheckboxChange?.(newSelected);
  };

  const handleClickIcon = (item: ITableRow) => {
    onClick?.(item);
  };

  const handleClickRow = (row: ITableRow) => {
    onClick?.(row);
  };

  return (
    <StyledTableContainer
      type={type}
      iswarning={isWarning}
      maxheight={maxheight}
    >
      {(action || title) && (
        <StyledWrapperTitle type={type}>
          <StyledTypography type={type}>{title}</StyledTypography>
          <StyledActionBlock>{action}</StyledActionBlock>
        </StyledWrapperTitle>
      )}
      <WrapperTable type={type}>
        <Table>
          <StyledTableHead>
            <StyledTableRow ischeckbox={isCheckbox} isicon={icon && 'true'}>
              {isCheckbox && (
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    checked={
                      tableData?.length > 0 &&
                      selectedRows.length === tableData.length
                    }
                    onChange={handleSelectAllCheckbox}
                  />
                </StyledTableCell>
              )}

              {columns.map(column => (
                <StyledTableCell key={column.label}>
                  {column.label}
                </StyledTableCell>
              ))}
              {icon && (
                <StyledTableCell>
                  {icon === 'edit'
                    ? 'Edit'
                    : icon === 'remove'
                      ? 'Remove'
                      : icon === 'check'
                        ? 'Check Activity'
                        : icon === 'switch'
                          ? 'Administrator'
                          : 'View'}
                </StyledTableCell>
              )}
            </StyledTableRow>
          </StyledTableHead>
          <TableBody>
            {tableData.map((rowData, index) => (
              <StyledTableRow
                ischeckbox={isCheckbox}
                isicon={icon && 'true'}
                key={index}
                selected={selectedRows.indexOf(index) !== -1}
                onClick={() => handleClickRow(rowData)}
              >
                {isCheckbox && (
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.indexOf(index) !== -1}
                      onChange={() => handleCheckboxClick(index)}
                    />
                  </StyledTableCell>
                )}
                {columns.map(column => (
                  <StyledTableCell key={column.label}>
                    {cellValue(rowData, column)}
                  </StyledTableCell>
                ))}
                {icon && (
                  <StyledTableCell>
                    <IconButton onClick={() => handleClickIcon(rowData)}>
                      {icon === 'edit' ? (
                        <IconEdit />
                      ) : icon === 'remove' ? (
                        <CloseIcon color="secondary" />
                      ) : icon === 'check' ? (
                        <RemoveRedEyeIcon color="secondary" />
                      ) : (
                        <IconGlass />
                      )}
                    </IconButton>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </WrapperTable>
    </StyledTableContainer>
  );
};

export default TableBasis;
