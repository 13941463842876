import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Navigate, useLocation } from 'react-router';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { getUserOrganizations } from 'reduxStore/organization/organizationOperations';
import { userGotOrganizations } from 'reduxStore/organization/organizationSelectors';
import { ROUTES } from 'routes/routes.const';

interface IProtectedRouteProps {
  children: React.JSX.Element;
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(getIsAuth);
  const userHasOrganizations = useAppSelector(userGotOrganizations);

  if (!isAuth) {
    return <Navigate to={ROUTES.SIGNIN} replace />;
  }

  if (location.state?.from === 'createMainOrganization') {
    dispatch(getUserOrganizations());
    return children;
  }

  if (!userHasOrganizations) {
    return <Navigate to={ROUTES.ORGANIZATION_PROFILE} replace />;
  }

  return children;
};

export default ProtectedRoute;
