import { Button, ButtonProps, styled } from '@mui/material';
import { theme } from 'theme';

interface IStepActionProps extends ButtonProps {
  width?: string;
  height?: string;
  isDisconnected?: 'true';
}

export const WrapperStepAction = styled(Button)<IStepActionProps>(
  ({ width = '80vw', height = '7vh', isDisconnected }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width,
    height,
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '-0.701px',
    borderRadius: '4px',
    border: 'none',
    color:
      isDisconnected === 'true'
        ? theme.palette.secondary.light
        : theme.palette.common.black,
    background:
      isDisconnected === 'true'
        ? theme.palette.action.disabled
        : theme.palette.primary.light,
    '&:hover': {
      border: 'none',
      color:
        isDisconnected === 'true'
          ? theme.palette.secondary.light
          : theme.palette.common.white,
      background:
        isDisconnected === 'true'
          ? theme.palette.action.disabled
          : theme.palette.primary.main,
    },
  })
);
