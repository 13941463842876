import React from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import {
  WrapperAddService,
  Title,
  MenuItemText,
  MenuItemBox,
  Error,
} from './AddService.styled';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect, useState } from 'react';
import {
  createIntegration,
  retrieveClients,
  checkAPICredentials,
  retrieveTools,
  retrieveToolOrgs,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getCreateProfileError,
  getToolOrgs,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { FlatButton } from 'ui';
import {
  IAuthData,
  ICreateIntegrationBody,
  IReceiveToolOrgsBody,
} from 'types/api.type';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

interface IServiceFormValue {
  tool_id: number;
  intgr_level: number;
  token: string;
  code: string;
  secret: string;
  server: string;
  type: number;
  client_id: number | null;
  tool_org_id: number | null;
  checked_API: boolean;
}

const validationSchema = Yup.object({
  tool_id: Yup.string()
    .required('Tool is required')
    .test('not-select', 'Tool is required', value => value !== '0'),
  intgr_level: Yup.string()
    .required('Integration Level is required')
    .test(
      'not-select',
      'Please select integration level',
      value => value !== '0'
    ),
  // TODO: Make Checking API mandatory in case of using API credentials
  // checked_API: Yup.string()
  //   .required('Check API')
  //   .test('not-select', 'Check API', value => value !== 'false'),
});

const AddService = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clients = useAppSelector(getClients);
  const tools = useAppSelector(getTools);
  const [selectedIntgrType, setSelectedIntgrType] = useState<number>(0);
  const [selectedTool, setSelectedTool] = useState<number>(0);
  const toolOrgs = useAppSelector(getToolOrgs);
  const error = useAppSelector(getCreateProfileError);

  useEffect(() => {
    dispatch(retrieveClients(null));
    dispatch(retrieveTools(null));
    const fetchToolOrgs = async () => {
      try {
        await dispatch(retrieveToolOrgs(selectedTool)).unwrap();
      } catch (error) {
        return;
      }
    };
    if (selectedIntgrType === 1 && selectedTool !== 0) {
      fetchToolOrgs();
    }
  }, [dispatch, selectedIntgrType, selectedTool]);

  const initialValues: IServiceFormValue = {
    tool_id: 0,
    intgr_level: 0,
    token: '',
    code: '',
    secret: '',
    server: '',
    type: 0,
    client_id: null,
    tool_org_id: null,
    checked_API: false,
  };

  const handleSubmit = async (value: IServiceFormValue) => {
    const body: ICreateIntegrationBody = {
      tool_id: value.tool_id,
      //TODO: Figure out how to fix if user enters API creds and then decides to use Client Level and vice versa
      client_id: value.intgr_level === 2 ? value.client_id : null,
      auth_data: checkAuthMethod(value),
      tool_org_id:
        value.intgr_level === 2 && value.type === 1 ? value.tool_org_id : null,
    };
    try {
      await dispatch(createIntegration(body)).unwrap();
      navigate(ROUTES.SERVICES);
      dispatch(
        setModalProps({
          radius: '18px',
          title: clients.find(client => client.id === value.client_id)?.name,
          additionalText: tools.find(tool => tool.id === value.tool_id)?.name,
        })
      );
      dispatch(openModal());
      dispatch(setContent('AddIntegrationConfirm'));
    } catch (error) {
      return;
    }
  };

  const handleAPICheck = async (value: IServiceFormValue) => {
    const body: IReceiveToolOrgsBody = {
      tool_id: value.tool_id,
      auth_data: checkAuthMethod(value),
    };

    try {
      await dispatch(checkAPICredentials(body)).unwrap();
    } catch (error) {
      return;
    }
  };

  const checkAuthMethod = (value: IServiceFormValue): IAuthData | null => {
    if (value.tool_org_id !== null) return null;
    const auth_method = tools.find(tool => tool.id === value.tool_id)
      ?.auth_method.id;
    switch (auth_method) {
      case 1:
        return {
          basic: {
            code: value.code,
            secret: value.secret,
          },
        };
      case 2:
        return {
          bearer: {
            token: value.token,
          },
        };
      case 3:
        return {
          vsax: {
            server: value.server,
            code: value.code,
            secret: value.secret,
          },
        };
      default:
        return {};
    }
  };

  return (
    <WrapperAddService>
      <Title>Create Integration</Title>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Field
                  name="tool_id"
                  as={TextField}
                  id="tool_id"
                  label="Select Service"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.tool_id && Boolean(errors.tool_id)}
                  helperText={touched.tool_id && errors.tool_id}
                  InputLabelProps={{ htmlFor: 'tool_id', shrink: true }}
                  onChange={(e: React.ChangeEvent<{ value: number }>) => {
                    const value = e.target.value;
                    setFieldValue('tool_id', value);
                    setSelectedTool(value);
                  }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Service</MenuItemText>
                  </MenuItem>
                  {Array.isArray(tools) &&
                    tools.map((tool, index) => (
                      <MenuItem key={index} value={tool.id} style={MenuItemBox}>
                        <MenuItemText>{tool.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid xs={12} paddingTop={2}>
                <Field
                  name="intgr_level"
                  as={TextField}
                  id="intgr_level"
                  label="Level Of Integration"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.intgr_level && Boolean(errors.intgr_level)}
                  helperText={touched.intgr_level && errors.intgr_level}
                  InputLabelProps={{ htmlFor: 'intgr_level', shrink: true }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Integration Level</MenuItemText>
                  </MenuItem>
                  <MenuItem value={1} style={MenuItemBox}>
                    <MenuItemText>MSP Level</MenuItemText>
                  </MenuItem>
                  <MenuItem value={2} style={MenuItemBox}>
                    <MenuItemText>Client Level</MenuItemText>
                  </MenuItem>
                </Field>
              </Grid>
              <Grid container xs={12} paddingTop={2} paddingBottom={2}>
                {values.intgr_level === 1 && (
                  <Grid container spacing={2} direction="row" padding={2}>
                    <Grid xs={6}>
                      {tools.find(tool => tool.id === values.tool_id)
                        ?.auth_method.id === 1 && (
                        <Grid xs={12}>
                          <Grid xs={12}>
                            <Field
                              name="code"
                              as={TextField}
                              label="Code"
                              variant="outlined"
                              placeholder="Enter Code"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid xs={12} paddingTop={2}>
                            <Field
                              name="secret"
                              as={TextField}
                              label="Secret"
                              variant="outlined"
                              placeholder="Enter Secret"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {tools.find(tool => tool.id === values.tool_id)
                        ?.auth_method.id === 2 && (
                        <Grid xs={12}>
                          <Field
                            name="token"
                            as={TextField}
                            label="Token"
                            variant="outlined"
                            placeholder="Enter Token"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      )}
                      {tools.find(tool => tool.id === values.tool_id)
                        ?.auth_method.id === 3 && (
                        <Grid xs={12}>
                          <Grid xs={12}>
                            <Field
                              name="server"
                              as={TextField}
                              label="Server"
                              variant="outlined"
                              placeholder="Enter Server Name"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid xs={12} paddingTop={2}>
                            <Field
                              name="code"
                              as={TextField}
                              label="Code"
                              variant="outlined"
                              placeholder="Enter Code"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid xs={12} paddingTop={2}>
                            <Field
                              name="secret"
                              as={TextField}
                              label="Secret"
                              variant="outlined"
                              placeholder="Enter Secret"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <FlatButton
                        width="200px"
                        variant="contained"
                        onClick={handleAPICheck}
                        isAPI="true"
                      >
                        Check API Credentials
                      </FlatButton>
                    </Grid>
                  </Grid>
                )}
                {values.intgr_level === 2 && (
                  <>
                    <Grid xs={12}>
                      <Field
                        name="type"
                        as={TextField}
                        id="type"
                        label="Type Of Integration"
                        variant="outlined"
                        select
                        fullWidth
                        InputLabelProps={{ htmlFor: 'type', shrink: true }}
                        onChange={(e: React.ChangeEvent<{ value: number }>) => {
                          const value = e.target.value;
                          setFieldValue('type', value);
                          setSelectedIntgrType(value);
                        }}
                      >
                        <MenuItem value={0} style={{ display: 'none' }}>
                          <MenuItemText>Select Integration Type</MenuItemText>
                        </MenuItem>
                        <MenuItem value={1} style={MenuItemBox}>
                          <MenuItemText>Global Integration</MenuItemText>
                        </MenuItem>
                        <MenuItem value={2} style={MenuItemBox}>
                          <MenuItemText>Native Integration</MenuItemText>
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid container paddingTop={2} paddingBottom={2}>
                      <Grid xs={6} paddingBottom={2}>
                        <Field
                          name="client_id"
                          as={TextField}
                          id="client_id"
                          label="Choose Client"
                          variant="outlined"
                          select
                          fullWidth
                          InputLabelProps={{
                            htmlFor: 'client_id',
                            shrink: true,
                          }}
                        >
                          <MenuItem value={0} style={{ display: 'none' }}>
                            <MenuItemText>Select Client</MenuItemText>
                          </MenuItem>
                          {Array.isArray(clients) &&
                            clients.map((client, index) => (
                              <MenuItem
                                key={index}
                                value={client.id}
                                style={MenuItemBox}
                              >
                                <MenuItemText>{client.name}</MenuItemText>
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>
                      {values.type === 1 && (
                        <Grid xs={6} paddingLeft={2}>
                          {/* TODO: change to a list from organizations in tool, received through API */}
                          <Field
                            name="tool_org_id"
                            as={TextField}
                            id="tool_org_id"
                            label="Choose Organization In Tool"
                            variant="outlined"
                            select
                            fullWidth
                            InputLabelProps={{
                              htmlFor: 'tool_org_id',
                              shrink: true,
                            }}
                          >
                            <MenuItem value={0} style={{ display: 'none' }}>
                              <MenuItemText>
                                Select Organization In Tool
                              </MenuItemText>
                            </MenuItem>
                            {Array.isArray(toolOrgs) &&
                              toolOrgs.map((tool_org, index) => (
                                <MenuItem
                                  key={index}
                                  value={tool_org.id}
                                  style={MenuItemBox}
                                >
                                  <MenuItemText>{tool_org.name}</MenuItemText>
                                </MenuItem>
                              ))}
                          </Field>
                        </Grid>
                      )}
                      {values.type === 2 && (
                        <Grid container spacing={2} direction="row" padding={2}>
                          <Grid xs={6}>
                            {tools.find(tool => tool.id === values.tool_id)
                              ?.auth_method.id === 1 && (
                              <Grid xs={12}>
                                <Grid xs={12}>
                                  <Field
                                    name="code"
                                    as={TextField}
                                    label="Code"
                                    variant="outlined"
                                    placeholder="Enter Code"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid xs={12} paddingTop={2}>
                                  <Field
                                    name="secret"
                                    as={TextField}
                                    label="Secret"
                                    variant="outlined"
                                    placeholder="Enter Secret"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {tools.find(tool => tool.id === values.tool_id)
                              ?.auth_method.id === 2 && (
                              <Grid xs={12}>
                                <Field
                                  name="token"
                                  as={TextField}
                                  label="Token"
                                  variant="outlined"
                                  placeholder="Enter Token"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                            )}
                            {tools.find(tool => tool.id === values.tool_id)
                              ?.auth_method.id === 3 && (
                              <Grid xs={12}>
                                <Grid xs={12}>
                                  <Field
                                    name="server"
                                    as={TextField}
                                    label="Server"
                                    variant="outlined"
                                    placeholder="Enter Server Name"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid xs={12} paddingTop={2}>
                                  <Field
                                    name="code"
                                    as={TextField}
                                    label="Code"
                                    variant="outlined"
                                    placeholder="Enter Code"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                                <Grid xs={12} paddingTop={2}>
                                  <Field
                                    name="secret"
                                    as={TextField}
                                    label="Secret"
                                    variant="outlined"
                                    placeholder="Enter Secret"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <FlatButton
                              width="200px"
                              variant="contained"
                              onClick={handleAPICheck}
                              isAPI="true"
                            >
                              Check API Credentials
                            </FlatButton>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                <FlatButton
                  width="200px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Create Integration
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {!!error && <Error>{error}</Error>}
    </WrapperAddService>
  );
};

export default AddService;
