import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography, TypographyProps } from '@mui/material';
import { INTER } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBackgroundBoxProps extends BoxProps {
  image: string;
}

export const ContainerSignIn = styled((props: BoxProps) => <Box {...props} />)(
  () => ({
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    boxSizing: 'border-box',
  })
);

export const WrapperContent = styled(Box)<BoxProps>(() => ({
  paddingLeft: '50px',
  paddingTop: '30px',
  width: '36%',
  minWidth: 'fit-content',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
}));

export const ContentBox = styled(Box)<BoxProps>(() => ({
  paddingLeft: '15%',
  paddingRight: '10px',
  marginTop: '10vh',
}));

export const ErrorText = styled(Typography)<TypographyProps>(() => ({
  paddingBottom: '10px',
  maxWidth: '400px',
  wordBreak: 'break-all',
  color: COLORS.authError,
  fontFamily: INTER,
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '150%',
  letterSpacing: '-0.352px',
}));

export const ImgBox = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
  minWidth: 'fit-content',
  backgroundColor: COLORS.grayBackgroundPrimary,
}));

export const BackgroundBox = styled((props: IBackgroundBoxProps) => (
  <Box {...props} sx={{ backgroundImage: `url(${props.image})` }} />
))(() => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  zIndex: 2,
  transform: 'translateY(-50%)',
  maxWidth: '1106px',
  width: '100%',
  height: '100%',
  maxHeight: '960px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left',
  backgroundSize: 'cover',
}));
