export const ROUTES = {
  DASHBOARD: '/',
  SIGNIN: '/login',
  REDIRECT: '/redirect',
  PROCESS_AUTH: '/process-auth',
  //TODO: it is a page that has wireframe for sys prog, not sure what to do with it
  SET_UP: '/set_up',

  ORGANIZATION_PROFILE: '/organization_profile',

  ADD_TEAM_MEMBER: '/add_team_member',
  DELETE_TEAM_MEMBER: '/delete_team_member',
  TEAM_MEMBERS: '/team_members',

  ADD_CLIENT: '/add_client',
  DELETE_CLIENT: '/delete_client',
  CLIENTS: '/clients',

  ADD_SERVICE: '/add_service',
  DELETE_INTEGRATION: '/delete_integration',
  SERVICES: '/services',
};

export const CREATE_ORG_ROUTES = [ROUTES.ORGANIZATION_PROFILE];
