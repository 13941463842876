import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setError, setLoading } from 'reduxStore/extraReducersHelpers';
import {
  IUserType,
  OrgLevelOption,
  ICloudFromServerType,
  ICloudFromServerReformatedType,
  IRegionFromServerType,
  IRegionFromServerReformatedType,
} from 'types/organizations.type';
import { ILoadableState } from 'types/store.type';
import {
  createOrganizationV1,
  retrieveClouds,
  updateOrganization,
  retrieveRoles,
  createContact,
  retrieveContacts,
  retrieveAssignableContacts,
  createOrganizationV2,
  retrieveClients,
  retrieveTools,
  checkAPICredentials,
  createIntegration,
  retrieveToolOrgs,
  deleteIntegration,
  deleteClient,
  deleteContact,
} from './createProfileOperations';
import {
  IAssignableContactBody,
  IReceiveContactBody,
  ICreateOrganizationBody,
  IRoleBody,
  IUpdateOrganizationBody,
  IClientStatisticBody,
  ITool,
  IToolOrg,
} from 'types/api.type';

//TODO: revise all fields, reducers and builders and delete unused, think if we need new folder for some of functionality

interface ICreateProfileState extends ILoadableState {
  creatingOrganization: boolean;
  organizationLevel: OrgLevelOption | null;
  // create / update organization
  organizationID: number | null;
  organizationValues: IUpdateOrganizationBody | ICreateOrganizationBody | null;
  // import users
  usersListImported: IUserType[] | null;
  clouds: ICloudFromServerReformatedType[];
  roles: IRoleBody[];
  contacts: IReceiveContactBody[];
  assignableContacts: IAssignableContactBody[];
  clients: IClientStatisticBody[];
  tools: ITool[];
  toolOrgs: IToolOrg[];
}

const initialState: ICreateProfileState = {
  creatingOrganization: false,
  organizationLevel: null,
  // create/update organization
  organizationID: null,
  organizationValues: null,
  // import users
  usersListImported: null,
  clouds: [],
  roles: [],
  contacts: [],
  assignableContacts: [],
  clients: [],
  tools: [],
  toolOrgs: [],
  loading: false,
  error: null,
};

const createProfileSlice = createSlice({
  name: 'createProfile',
  initialState,
  reducers: {
    setCreatingOrganization(state, action: PayloadAction<boolean>) {
      state.creatingOrganization = action.payload;
    },
    setOrganizationLevel(state, action: PayloadAction<OrgLevelOption | null>) {
      state.organizationLevel = action.payload;
    },
    setOrganizationValues(
      state,
      action: PayloadAction<
        IUpdateOrganizationBody | ICreateOrganizationBody | null
      >
    ) {
      state.organizationValues = action.payload;
    },
    setUsersList(state, action: PayloadAction<IUserType[] | null>) {
      state.usersListImported = action.payload;
    },
    addOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = true;
            }
          });
        }
      });
    },
    removeOption(
      state,
      action: PayloadAction<{ cloudProvider: string; regionId: number }>
    ) {
      const { cloudProvider, regionId } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            if (region.defaultRegion.id === regionId) {
              region.checked = false;
            }
          });
        }
      });
    },

    selectAllOptions(state, action: PayloadAction<{ cloudProvider: string }>) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = true;
          });
        }
      });
    },
    deselectAllOptions(
      state,
      action: PayloadAction<{ cloudProvider: string }>
    ) {
      const { cloudProvider } = action.payload;
      state.clouds.forEach((cloud: ICloudFromServerReformatedType) => {
        if (cloud.name === cloudProvider) {
          cloud.regions.forEach((region: IRegionFromServerReformatedType) => {
            region.checked = false;
          });
        }
      });
    },
    clearCreateProfileData() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      // createOrganizationV1
      .addCase(createOrganizationV1.pending, setLoading)
      .addCase(createOrganizationV1.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.organizationID = parseInt(payload.id) ?? null;
      })
      .addCase(createOrganizationV1.rejected, setError)
      // createOrganizationV2
      .addCase(createOrganizationV2.pending, setLoading)
      .addCase(createOrganizationV2.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createOrganizationV2.rejected, setError)
      // updateOrganization
      .addCase(updateOrganization.pending, setLoading)
      .addCase(updateOrganization.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateOrganization.rejected, setError)
      // retrieveClouds
      .addCase(retrieveClouds.pending, setLoading)
      .addCase(retrieveClouds.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload) {
          const updatedClouds: ICloudFromServerReformatedType[] =
            payload.clouds.map((cloud: ICloudFromServerType) => {
              const regions: IRegionFromServerReformatedType[] =
                cloud.regions.map((region: IRegionFromServerType) => ({
                  defaultRegion: { ...region },
                  checked: false,
                }));
              return {
                ...cloud,
                regions: regions,
              };
            });
          state.clouds = updatedClouds;
        }
      })
      .addCase(retrieveClouds.rejected, setError)
      // retrieveRoles
      .addCase(retrieveRoles.pending, setLoading)
      .addCase(retrieveRoles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.roles = payload.roles;
      })
      .addCase(retrieveRoles.rejected, setError)
      // createContact
      .addCase(createContact.pending, setLoading)
      .addCase(createContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createContact.rejected, setError)
      // retrieveContacts
      .addCase(retrieveContacts.pending, setLoading)
      .addCase(retrieveContacts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.contacts = payload.contacts;
      })
      .addCase(retrieveContacts.rejected, setError)
      // retrieveAssignableContacts
      .addCase(retrieveAssignableContacts.pending, setLoading)
      .addCase(retrieveAssignableContacts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.assignableContacts = payload.contacts;
      })
      .addCase(retrieveAssignableContacts.rejected, setError)
      // retrieveClients
      .addCase(retrieveClients.pending, setLoading)
      .addCase(retrieveClients.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clients = payload.clients;
      })
      .addCase(retrieveClients.rejected, setError)
      // retrieveTools
      .addCase(retrieveTools.pending, setLoading)
      .addCase(retrieveTools.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tools = payload.tools;
      })
      .addCase(retrieveTools.rejected, setError)
      // checkAPICredentials
      .addCase(checkAPICredentials.pending, setLoading)
      .addCase(checkAPICredentials.fulfilled, state => {
        state.loading = false;
      })
      .addCase(checkAPICredentials.rejected, setError)
      // createIntegration
      .addCase(createIntegration.pending, setLoading)
      .addCase(createIntegration.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createIntegration.rejected, setError)
      // retrieveToolOrgs
      .addCase(retrieveToolOrgs.pending, setLoading)
      .addCase(retrieveToolOrgs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.toolOrgs = payload.tool_orgs;
      })
      .addCase(retrieveToolOrgs.rejected, setError)
      // deleteIntegration
      .addCase(deleteIntegration.pending, setLoading)
      .addCase(deleteIntegration.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deleteIntegration.rejected, setError)
      // deleteClient
      .addCase(deleteClient.pending, setLoading)
      .addCase(deleteClient.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deleteClient.rejected, setError)
      // deleteContact
      .addCase(deleteContact.pending, setLoading)
      .addCase(deleteContact.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deleteContact.rejected, setError);
  },
});

export const {
  setCreatingOrganization,
  setOrganizationLevel,
  setOrganizationValues,
  setUsersList,
  addOption,
  removeOption,
  selectAllOptions,
  deselectAllOptions,
  clearCreateProfileData,
} = createProfileSlice.actions;

export default createProfileSlice.reducer;
