import { FlatButton } from 'ui';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { WrapperConfirmation, MainText } from './AddTeamMemberConfirm.styled';
import { Box } from '@mui/material';

interface IAddClientConfirmProps {
  emailOfContact: string | undefined;
}

const AddTeamMemberConfirm = ({ emailOfContact }: IAddClientConfirmProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperConfirmation>
      <Box width="500px" height="96px">
        <MainText>
          {emailOfContact}
          <br /> has been added as a team member
        </MainText>
      </Box>

      <FlatButton
        onClick={handleClick}
        variant="contained"
        width="134px"
        height="50px"
      >
        OK
      </FlatButton>
    </WrapperConfirmation>
  );
};
export default AddTeamMemberConfirm;
