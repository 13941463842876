import { useState, SyntheticEvent, useEffect } from 'react';
import { useLocation } from 'react-router';
import Tab from '@mui/material/Tab';
import {
  StyledTabs,
  StyledLink,
  StyledBox,
  StyledSubTabs,
  CompanyName,
} from './MainNavigationMenu.styled';
import { IAdminType, ITabMenuProps } from 'types';
import { initiateTabValueForNavTabs } from 'utils';
import React from 'react';
import { Collapse } from '@mui/material';
// import { IOrganizationByID } from 'types/organizations.type';
// import useGetAPI from 'hooks/useGetApi';
import { useAppSelector } from 'hooks';
import {
  getMainOrganization,
  //getMainOrganizationID,
} from 'reduxStore/organization/organizationSelectors';

interface INavTabsProps extends IAdminType {
  options: ITabMenuProps[];
}

//TODO: Deal with commented parts
const MainNavigationMenu = ({ options, type }: INavTabsProps) => {
  const { pathname } = useLocation();
  //const mainOrganizationId = useAppSelector(getMainOrganizationID);
  const mainOrganization = useAppSelector(getMainOrganization);

  // const [mainOrganization] = useGetAPI<IOrganizationByID | null>(
  //   'v1/organization',
  //   {
  //     id: mainOrganizationId,
  //   }
  // );

  const initiateValue = () =>
    initiateTabValueForNavTabs(options, pathname, true);

  const [value, setValue] = useState(initiateValue);
  const [openDropdowns, setOpenDropdowns] = useState<Record<number, boolean>>(
    {}
  );

  useEffect(() => {
    if (pathname !== '/') {
      const parentPath = pathname.split('/').filter(String)[0];
      const parentIndex = options.findIndex(i => i.url === `/${parentPath}`);
      setValue(parentIndex !== -1 ? parentIndex : 0);
    }
    if (pathname === '/') {
      setValue(0);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDropdownClick = (index: number) => {
    setOpenDropdowns(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <StyledBox>
      <CompanyName>{mainOrganization?.name}</CompanyName>
      <StyledTabs type={type} value={value} onChange={handleChange}>
        {options.map(({ title, url, icon, subOptions }, index) => (
          <React.Fragment key={`navigation-tab-${index}`}>
            <Tab
              type={type}
              iconPosition="start"
              icon={icon}
              label={title}
              component={StyledLink}
              to={url}
              onClick={
                subOptions ? () => handleDropdownClick(index) : undefined
              }
            />
            {subOptions && (
              <Collapse in={openDropdowns[index]} timeout="auto" unmountOnExit>
                <StyledSubTabs>
                  {subOptions.map((subOption, index) => (
                    <Tab
                      key={`sub-nav-tab-${index}`}
                      type={type}
                      iconPosition="start"
                      icon={subOption.icon}
                      label={subOption.title}
                      component={StyledLink}
                      to={subOption.url}
                    />
                  ))}
                </StyledSubTabs>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </StyledTabs>
    </StyledBox>
  );
};

export default MainNavigationMenu;
