import { styled } from '@mui/material';
import Table, { TableProps } from '@mui/material/Table';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import { ADMIN, SUPER_ADMIN } from 'constants/organizationsType';
import { IAdminType } from 'types';
import { INTER, ISTOK_WEB, POPPINS } from 'constants/fonts';
import { COLORS } from 'theme/colors.const';

interface IBoxProps extends BoxProps, IAdminType {}

interface ITypographyProps extends TypographyProps, IAdminType {}

interface ITableContainerProps extends TableContainerProps, IAdminType {
  maxheight?: string;
  iswarning?: boolean;
}
interface ITableRowProps extends TableRowProps {
  ischeckbox?: boolean;
  isicon?: 'true';
}

export const StyledTableContainer = styled(
  TableContainer
)<ITableContainerProps>(({ theme, type, maxheight, iswarning }) => ({
  position: 'relative',
  border: iswarning ? `2px solid ${theme.palette.error.light}` : 'none',
  background:
    type === SUPER_ADMIN
      ? `linear-gradient(158deg, ${theme.palette.blue.main} 27.45%, ${theme.palette.primary.main} 82.24%)`
      : theme.palette.common.white,
  width: '100%',
  height: '100%',
  padding: '19px 0 0',
  overflowY: maxheight ? 'auto' : 'visible',
  overflowX: 'visible',
  scrollBehavior: 'smooth',
  '& .MuiTableHead-root': {
    borderBottom:
      type === SUPER_ADMIN ? `4px solid ${COLORS.snowBlue}` : 'none',
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      color:
        type === SUPER_ADMIN
          ? theme.palette.secondary.main
          : theme.palette.primary.dark,
    },
  },
  '& .MuiTableRow-root': {
    paddingLeft: '40px',
    paddingRight: '0px',
    '&:nth-of-type(even)': {
      background:
        type === SUPER_ADMIN
          ? COLORS.snowBlue
          : // : type === ADMIN
            //   ? theme.palette.silver.main
            theme.palette.common.white,
    },
    '&:nth-of-type(odd)': {
      background:
        type === SUPER_ADMIN
          ? '#037CC0'
          : type === ADMIN
            ? theme.palette.silver.main
            : theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: type === SUPER_ADMIN && '#005A8D',
    },
  },
  '& .MuiTableCell-root': {
    fontSize: type === SUPER_ADMIN ? '14px' : '16px',
    fontFamily: type === SUPER_ADMIN ? INTER : ISTOK_WEB,
    lineHeight: type === SUPER_ADMIN ? '21px' : '24px',
    letterSpacing: type === SUPER_ADMIN ? '-0.308px' : '-0.352px',
    // textTransform: type === SUPER_ADMIN ? 'uppercase' : 'initial',
    color:
      type === SUPER_ADMIN
        ? theme.palette.common.white
        : theme.palette.secondary.light,
  },
  '& .MuiTableCell-paddingCheckbox:first-of-type': {
    width: '24px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '247px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.secondary.contrastText,
    borderRadius: '17px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const WrapperTable = styled(Box)<IBoxProps>(({ type }) => ({
  paddingBottom: '19px',
  background: type === SUPER_ADMIN ? COLORS.snowBlue : 'none',
  width: '100%',
  minHeight: type === SUPER_ADMIN ? '70vh' : '100%',
}));

export const StyledTable = styled(Table)<TableProps>(() => ({
  borderSpacing: '0px 9px',
  borderCollapse: 'separate',
  backgroundColor: COLORS.snowBlue,
}));

export const StyledTableHead = styled(TableHead)<TableHeadProps>(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    marginBottom: '19px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '& .MuiTableCell-head:not(:first-of-type)': {
      textAlign: 'center',
    },
    // '& .MuiTableCell-head:last-of-type': {
    //   textAlign: 'end',
    // },
    '& .MuiTableCell-head:first-of-type': {
      textAlign: 'start',
    },
    '& .MuiTableCell-head': {
      fontFamily: INTER,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.308px',
      textTransform: 'uppercase',
    },
  })
);

export const StyledTableRow = styled(TableRow)<ITableRowProps>(
  ({ ischeckbox, isicon }) => ({
    height: '36px',
    cursor: 'pointer',
    padding: '0 40px',
    '& .MuiTableCell-head:nth-of-type(2)': {
      textAlign: ischeckbox ? 'start' : 'center',
    },
    '& .MuiTableCell-root:nth-of-type(2)': {
      textAlign: ischeckbox ? 'start' : 'center',
    },
    '& .MuiTableCell-root:last-of-type': {
      width: isicon ? '120px' : 'inherit',
      minWidth: isicon && '80px',
    },
    '& .MuiTableCell-root:first-of-type': {
      minWidth: ischeckbox && '48px',
    },
  })
);

export const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  fontWeight: '400',
  border: 'none',
  marginLeft: 0,
  padding: '3px',
  // minWidth: '170px',
  '&:not(:first-of-type)': {
    textAlign: 'center',
  },
  '&:last-of-type': {
    // textAlign: 'end',
    textAlign: 'center',
  },
  '&:first-of-type': {
    textAlign: 'start',
    paddingLeft: '40px',
  },
}));

export const StyledWrapperTitle = styled(Box)<IBoxProps>(({ type }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  marginBottom: type === SUPER_ADMIN ? '14px' : '20px',
  paddingLeft: '40px',
  paddingRight: '40px',
}));

export const StyledTypography = styled(Typography)<ITypographyProps>(
  ({ theme, type }) => ({
    color:
      type === SUPER_ADMIN
        ? theme.palette.common.white
        : theme.palette.primary.main,
    fontFamily: type === SUPER_ADMIN ? POPPINS : INTER,
    fontSize: type === SUPER_ADMIN ? '36px' : '16px',
    fontWeight: type === SUPER_ADMIN ? 600 : 500,
    lineHeight: type === SUPER_ADMIN ? '54px' : '24px',
    letterSpacing: type === SUPER_ADMIN ? '-0.792px' : '-0.352px',
  })
);

export const StyledActionBlock = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  right: '40px',
  width: 'fit-content',
  height: 'fit-content',
}));
