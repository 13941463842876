import { BasicLinks, FlatButton } from 'ui';
import { Box } from '@mui/material';
import mainImg from '../../assets/images/mainImg.png';
import {
  ContainerSignIn,
  WrapperContent,
  ContentBox,
  ErrorText,
  BackgroundBox,
  ImgBox,
} from './SignOn.styled';
import { generateAuthUrl, getPopupWindowParams } from '../../utils';
import { useAppDispatch, useAppSelector, useAuthListener } from 'hooks';
import { getAuthError } from 'reduxStore/auth/authSelectors';
import { resetAuthError } from 'reduxStore/auth/authSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const SignOn = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authError = useAppSelector(getAuthError);

  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [code, resetCode] = useAuthListener(popupWindow);

  useEffect(() => {
    if (!code) return;
    const savedCode = code;
    resetCode();
    navigate(ROUTES.PROCESS_AUTH, { state: { code: savedCode } });
  }, [code, resetCode, navigate]);

  const handleSignIn = () => {
    dispatch(resetAuthError());
    const authUrl = generateAuthUrl();

    const authWindow = window.open(
      authUrl,
      'authWindow',
      getPopupWindowParams(500, 700)
    );
    setPopupWindow(authWindow);

    // window.location.href = authUrl;
  };

  return (
    <ContainerSignIn>
      <WrapperContent>
        <Box>
          <ContentBox>
            <Box>
              <FlatButton variant="contained" onClick={handleSignIn}>
                Sign In
              </FlatButton>
            </Box>
            <Box marginTop="100px">
              <BasicLinks
                text="New to Kylada?"
                nameLink="Sign up now"
                onClick={handleSignIn}
              />
            </Box>
            {authError && (
              <Box marginTop="50px">
                <ErrorText>{authError.title}</ErrorText>
                <ErrorText>{authError.text}</ErrorText>
              </Box>
            )}
          </ContentBox>
        </Box>
      </WrapperContent>
      <ImgBox>
        <BackgroundBox image={mainImg} />
      </ImgBox>
    </ContainerSignIn>
  );
};

export default SignOn;
