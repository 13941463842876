import { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { SectionTitle } from 'ui';
import { Input } from 'ui/inputs';
import { ROUTES } from 'routes/routes.const';
import { ButtonsContainerMSP } from 'views';
import InputMask from 'react-input-mask';
import { Error } from './OrgProfile.styled';
import { useNavigate } from 'react-router';
import { OrgTypeOption } from 'types/organizations.type';
import createValidationSchema from './validationSchema';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SectionUpload } from './components';
import { BYTES_IN_2MB } from 'constants/validation';
import { getBase64 } from 'utils';
import { CHILD, COMPANY, MSP, PARENT } from 'constants/organizationsType';
import { IOrgProfileFormValues } from 'types';
import { getOrganizationRequestBody } from 'utils/apiHelpers';
import {
  createOrganizationV1,
  updateOrganization,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getCreateProfileError,
  getOrganizationId,
  getOrganizationLevel,
  getOrganizationValues,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  ICreateOrganizationBody,
  IUpdateOrganizationBody,
} from 'types/api.type';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { setOrganizationValues } from 'reduxStore/createProfile/createProfileSlice';
import { getIsAuth } from 'reduxStore/auth/authSelectors';
import { resetAuth } from 'reduxStore/auth/authSlice';
import { resetOrganization } from 'reduxStore/organization/organizationSlice';

export type ButtonsOrgTypeStatus = 'active' | 'inactive';

const OrgProfile = () => {
  const inputRef1 = useRef<HTMLInputElement | null>(null);
  const inputRef3 = useRef<HTMLInputElement | null>(null);
  const inputRef4 = useRef<HTMLInputElement | null>(null);
  const inputRef5 = useRef<HTMLInputElement | null>(null);
  const inputRef6 = useRef<HTMLInputElement | null>(null);
  //const inputRef7 = useRef<HTMLInputElement | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const organizationLevel = useAppSelector(getOrganizationLevel);
  const organizationID = useAppSelector(getOrganizationId);
  const organizationValues = useAppSelector(getOrganizationValues);
  const mspId = useAppSelector(getMainOrganizationID);
  const error = useAppSelector(getCreateProfileError);

  const isAuth = useAppSelector(getIsAuth);

  const handleLogout = () => {
    if (isAuth) {
      dispatch(resetAuth());
      dispatch(resetOrganization());
    }
  };

  const setInitialOrgType = () => {
    if (organizationValues) {
      return organizationValues?.is_msp ? MSP : COMPANY;
    }
    return MSP;
  };
  console.log(organizationLevel);
  const [base64Image, setBase64Image] = useState(
    organizationValues?.logo || ''
  );
  const [imageError, setImageError] = useState<string | null>(null);
  const [selectedOrgType] = useState<OrgTypeOption>(setInitialOrgType);

  const handleDeleteImage = () => {
    setBase64Image('');
    setImageError(null);
  };

  const handleImageError = (error: string) => {
    setBase64Image('');
    setImageError(error);
    setTimeout(() => setImageError(null), 3000);
  };

  const handleUpload = async (image: File) => {
    if (!(image instanceof File)) {
      handleImageError('Invalid file type. Please upload a valid image file.');
      return;
    }
    setImageError(null);

    if (image.size > BYTES_IN_2MB) {
      handleImageError('Image size must be less than 2MB');
      return;
    }
    try {
      await getBase64(
        image,
        result => setBase64Image(result),
        () => handleImageError('Error uploading image. Please, try again')
      );
    } catch (err) {
      handleImageError('Error uploading image. Please, try again');
    }
  };
  const setInitialValues = (): IOrgProfileFormValues => {
    const values = {
      name: organizationValues?.name ?? '',
      admins: organizationValues?.admins ?? [],
      street: organizationValues?.address.street ?? '',
      city: organizationValues?.address.city ?? '',
      state: organizationValues?.address.state ?? '',
      zipCode: organizationValues?.address.zip_code ?? '',
    };
    if (organizationLevel === CHILD) {
      const margin = organizationValues?.margin?.toString() ?? '';
      return { ...values, margin };
    }
    return values;
  };

  const handleSubmit = async (values: IOrgProfileFormValues) => {
    const body = getOrganizationRequestBody(values, {
      orgType: organizationLevel === PARENT ? selectedOrgType : undefined,
      logo: base64Image,
      id: organizationID, // for update organization
      ...(organizationLevel === CHILD && { mspId }),
    });

    try {
      if (organizationID) {
        await dispatch(
          updateOrganization(body as IUpdateOrganizationBody)
        ).unwrap();
      } else {
        await dispatch(
          createOrganizationV1(body as ICreateOrganizationBody)
        ).unwrap();
      }
      dispatch(setOrganizationValues(body));
      navigate(ROUTES.DASHBOARD, { state: { from: 'createMainOrganization' } });
    } catch (error) {
      return;
    }
  };

  return (
    <Box component="section" position="relative">
      <SectionTitle>Organization Profile</SectionTitle>
      <Formik
        initialValues={setInitialValues()}
        validationSchema={createValidationSchema(organizationLevel)}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box display="flex" flexDirection="row" gap="15vw" marginTop="46px">
              <Box>
                <Grid container spacing="38px" width="100%" maxWidth="630px">
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="name"
                      label="Company Name"
                      inputRef={inputRef1}
                      helperText={<ErrorMessage name="name" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="street"
                      label="Address"
                      inputRef={inputRef3}
                      helperText={<ErrorMessage name="street" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={Input}
                      name="city"
                      label="City"
                      inputRef={inputRef4}
                      helperText={<ErrorMessage name="city" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={Input}
                      name="state"
                      label="State"
                      inputRef={inputRef5}
                      helperText={<ErrorMessage name="state" />}
                      background="gray"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="zipCode">
                      {({ field, form }: any) => (
                        <InputMask
                          mask="99999"
                          maskChar="_"
                          {...field}
                          field={field}
                          form={form}
                        >
                          {(inputProps: any) => (
                            <Input
                              {...inputProps}
                              background="gray"
                              label="Zip code"
                              inputRef={inputRef6}
                              helperText={<ErrorMessage name="zipCode" />}
                              field={inputProps.field}
                              form={inputProps.form}
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Box>
              <SectionUpload
                handleUpload={handleUpload}
                onDelete={handleDeleteImage}
                imageUrl={base64Image}
                error={imageError}
              />
            </Box>
            <Box marginTop="47px">
              <ButtonsContainerMSP
                titleButton="Register"
                type="submit"
                onClickBack={handleLogout}
              />
            </Box>
          </Form>
        )}
      </Formik>

      {error && <Error>{error}</Error>}
    </Box>
  );
};

export default OrgProfile;
