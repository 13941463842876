import { SelectActions, TableBasis } from 'ui';
import { Wrapper } from './TeamMembersPage.styled';
import { TEAM_MEMBERS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { useEffect } from 'react';
import { retrieveContacts } from 'reduxStore/createProfile/createProfileOperations';
import { getContacts } from 'reduxStore/createProfile/createProfileSelectors';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const TeamMembersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const contacts = useAppSelector(getContacts);
  const organizationId = useAppSelector(getMainOrganizationID);

  const handleAddTeamMember = () => {
    navigate(ROUTES.ADD_TEAM_MEMBER);
  };

  const handleDeleteTeamMember = () => {
    navigate(ROUTES.DELETE_TEAM_MEMBER);
  };

  useEffect(() => {
    dispatch(retrieveContacts(organizationId));
  }, [dispatch, organizationId]);

  const reformatedContacts = Array.isArray(contacts)
    ? contacts.map(contact => {
        return {
          first_name: contact.first_name,
          last_name: contact.last_name,
          phone: contact.phone,
          email: contact.email,
          role: contact.role?.name,
        };
      })
    : [];

  return (
    <Wrapper>
      <TableBasis
        tableData={reformatedContacts}
        columns={TEAM_MEMBERS_TABLE}
        title="Team Members"
        icon="edit"
        action={
          <SelectActions
            name="Team Members"
            onAdd={handleAddTeamMember}
            onDelete={handleDeleteTeamMember}
          />
        }
      ></TableBasis>
    </Wrapper>
  );
};

export default TeamMembersPage;
