import { MainNavigationMenu } from 'ui';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

import { ROUTES } from 'routes/routes.const';

const Navigation = () => {
  const navigationOptions = [
    {
      title: 'Clients',
      url: ROUTES.DASHBOARD,
      icon: <BusinessIcon />,
    },
    {
      // TODO: Fix Setup as a menu item - it is just drop down
      title: 'Setup',
      url: ROUTES.SET_UP,
      icon: <SettingsOutlinedIcon />,
      subOptions: [
        {
          title: 'Services',
          url: ROUTES.SERVICES,
          icon: <HomeRepairServiceOutlinedIcon />,
        },
        {
          title: 'Clients',
          url: ROUTES.CLIENTS,
          icon: <BusinessIcon />,
        },
        {
          title: 'Team',
          url: ROUTES.TEAM_MEMBERS,
          icon: <PersonOutlineOutlinedIcon key="users" />,
        },
        {
          title: 'Ticketing',
          url: ROUTES.DASHBOARD,
          icon: <LocalActivityOutlinedIcon />,
        },
        {
          title: 'Audit Logs',
          url: ROUTES.DASHBOARD,
          icon: <ListAltOutlinedIcon />,
        },
      ],
    },
  ];

  return <MainNavigationMenu options={navigationOptions} />;
};

export default Navigation;
