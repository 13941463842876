import { StepAction } from 'ui';
import {
  WrapperGeneral,
  WrapperSteps,
  WrapperInfoBoxes,
} from './DashboardPage.styled';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getContacts,
  getTools,
  getClients,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect } from 'react';
import {
  retrieveClients,
  retrieveContacts,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';

const DashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const services = useAppSelector(getTools);
  const clients = useAppSelector(getClients);
  const teamMembers = useAppSelector(getContacts);
  const orgId = useAppSelector(getMainOrganizationID);

  useEffect(() => {
    dispatch(retrieveClients(null));
    dispatch(retrieveTools(null));
    dispatch(retrieveContacts(orgId));
  }, [dispatch, orgId]);

  const handleAddTeamMembers = () => {
    navigate(ROUTES.ADD_TEAM_MEMBER);
  };
  const handleAddClients = () => {
    navigate(ROUTES.ADD_CLIENT);
  };
  const handleAddService = () => {
    navigate(ROUTES.ADD_SERVICE);
  };
  const handleOpenTeamMembers = () => {
    navigate(ROUTES.TEAM_MEMBERS);
  };
  const handleOpenClients = () => {
    navigate(ROUTES.CLIENTS);
  };
  const handleOpenServices = () => {
    navigate(ROUTES.SERVICES);
  };

  const connectedServices = services.filter(
    tool =>
      (tool.intgrn !== null && tool.intgrn.has_creds === true) ||
      (tool.clients_count !== null && tool.clients_count !== 0)
  );

  return (
    <WrapperGeneral>
      <WrapperInfoBoxes>
        {Array.isArray(clients) && clients.length > 0 && (
          <StepAction onClick={handleOpenClients} width="20vw" height="40vh">
            {clients.length} Clients
          </StepAction>
        )}
        {Array.isArray(teamMembers) && teamMembers.length > 1 && (
          <StepAction
            onClick={handleOpenTeamMembers}
            width="20vw"
            height="40vh"
          >
            {teamMembers.length} Team Members
          </StepAction>
        )}
        {Array.isArray(connectedServices) && connectedServices.length > 0 && (
          <StepAction onClick={handleOpenServices} width="20vw" height="40vh">
            {connectedServices.length} Connected Services
          </StepAction>
        )}
      </WrapperInfoBoxes>
      <WrapperSteps>
        <StepAction onClick={handleAddService}>
          Step 1. Create Service Integrations
        </StepAction>
        <StepAction onClick={handleAddClients}>Step 2. Add Clients</StepAction>
        <StepAction onClick={handleAddTeamMembers}>
          Step 3. Add Team Members
        </StepAction>
      </WrapperSteps>
    </WrapperGeneral>
  );
};

export default DashboardPage;
