import { useAppDispatch, useAppSelector } from 'hooks';
import * as Yup from 'yup';
import { retrieveClients } from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getCreateProfileError,
} from 'reduxStore/createProfile/createProfileSelectors';
import {
  MenuItemBox,
  MenuItemText,
  Title,
  WrapperDeleteClient,
  Error,
} from './DeleteClient.styled';
import { Formik, Form, Field } from 'formik';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { FlatButton } from 'ui';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';

interface IDeleteClientForm {
  client_id: number;
}

const validationSchema = Yup.object({
  client_id: Yup.string()
    .required('Client is required')
    .test('not-select', 'Client is required', value => value !== '0'),
});

const DeleteContact = () => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(getCreateProfileError);
  const clients = useAppSelector(getClients);

  const handleSubmit = (value: IDeleteClientForm) => {
    dispatch(
      setModalProps({
        radius: '18px',
        title: 'client',
        apiValue: value.client_id,
      })
    );
    dispatch(openModal());
    dispatch(setContent('DeleteConfirmation'));
  };

  useEffect(() => {
    dispatch(retrieveClients(null));
  }, [dispatch]);

  const initialValues: IDeleteClientForm = {
    client_id: 0,
  };

  return (
    <WrapperDeleteClient>
      <Title>Delete Client</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  name="client_id"
                  as={TextField}
                  label="Select Client"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.client_id && Boolean(errors.client_id)}
                  helperText={touched.client_id && errors.client_id}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Client To Delete</MenuItemText>
                  </MenuItem>
                  {Array.isArray(clients) &&
                    clients.map(client => (
                      <MenuItem
                        key={client.id}
                        value={client.id}
                        style={MenuItemBox}
                      >
                        <MenuItemText>{client.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>
              <Grid item xs={8}>
                <FlatButton width="150px" type="submit" variant="contained">
                  Delete Client
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {!!error && <Error>{error}</Error>}
    </WrapperDeleteClient>
  );
};

export default DeleteContact;
