import { Outlet } from 'react-router';
import { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
//import { scrollToTop } from 'utils';
// import { SelectOrganization, IconButton, IconHorn } from 'ui';
// import { Search } from 'ui/inputs';
import { WrapperContainer } from './MainLayoutPage.styled';
import { SideBar } from 'views';
//import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
//import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAppDispatch } from 'hooks';
//import { getUsersList } from 'reduxStore/users/usersOperations';
// import {
//   getMainOrganizationID,
//   getSelectedClient,
// } from 'reduxStore/organization/organizationSelectors';
import { getUserOrganizations } from 'reduxStore/organization/organizationOperations';

const MainLayoutPage = () => {
  //const pathname = useLocation();
  const dispatch = useAppDispatch();

  //const mainOrganizationId = useAppSelector(getMainOrganizationID);
  //const selectedOrganization = useAppSelector(getSelectedClient);
  //const selectedOrganizationId = selectedOrganization?.[0]?.id;

  // useEffect(() => {
  //   scrollToTop();
  // }, [pathname]);

  useEffect(() => {
    dispatch(getUserOrganizations());
  }, [dispatch]);

  // useEffect(() => {
  //   if (!selectedOrganizationId) {
  //     dispatch(getUsersList(mainOrganizationId));
  //   } else {
  //     dispatch(getUsersList(selectedOrganizationId));
  //   }
  // }, [mainOrganizationId, selectedOrganizationId, dispatch]);

  return (
    <Stack direction="row" width="100vw">
      <SideBar />
      <WrapperContainer>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap="25px"
          width="100%"
        >
          {/* <Search />
          <SelectOrganization />
          <IconButton>
            <IconHorn size="24px" />
          </IconButton>
          <IconButton>
            <NotificationsNoneOutlinedIcon fontSize="medium" />
          </IconButton>
          <IconButton>
            <AccountCircleOutlinedIcon fontSize="medium" />
          </IconButton> */}
        </Box>
        <Outlet />
      </WrapperContainer>
    </Stack>
  );
};

export default MainLayoutPage;
