import React from 'react';
import { WrapperStepAction } from './StepAction.styled';

interface IStepActionProps {
  onClick?: () => void;
  children: React.ReactNode | string;
  width?: string;
  height?: string;
  isDisconnected?: 'true';
}

const StepAction = ({
  onClick,
  children,
  width,
  height,
  isDisconnected,
}: IStepActionProps) => {
  return (
    <WrapperStepAction
      onClick={onClick}
      width={width}
      height={height}
      isDisconnected={isDisconnected}
    >
      {children}
    </WrapperStepAction>
  );
};
export default StepAction;
