import { useAppDispatch, useAppSelector } from 'hooks';
import { closeModal } from 'reduxStore/modal/modalSlice';
import {
  getIsOpen,
  getContent,
  getModalProps,
} from 'reduxStore/modal/modalSelectors';
import { StyledDialog, StyledWrapperDialog, StyledLine } from './Modal.styled';
import {
  AddClientConfirm,
  AddIntegrationConfirm,
  AddTeamMemberConfirm,
  DeleteConfirmation,
} from './components';
import { IAdminType, ITableColumn, ITableRow } from 'types';

//TODO: possibly needs refactoring because not everything is needed
export interface IModalProps extends IAdminType {
  status?: 'error' | 'success';
  isAdmin?: boolean;
  position?: 'top' | 'right';
  top?: string;
  radius?: string;
  isNextClick?: boolean;
  background?: string;
  zIndex?: number;
  title?: string;
  additionalText?: string;
  icon?: 'edit' | 'remove' | 'check' | 'switch' | 'view';
  columns?: ITableColumn[];
  tableData?: ITableRow[];
  isCheckbox?: 'true';
  progress?: number;
  apiValue?: any;
}

const Modal = () => {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(getIsOpen);
  const content = useAppSelector(getContent);
  const modalProps = useAppSelector(getModalProps);

  const modalContent = () => {
    switch (content) {
      case 'AddClientConfirm':
        return <AddClientConfirm nameOfClient={modalProps?.title} />;
      case 'AddTeamMemberConfirm':
        return <AddTeamMemberConfirm emailOfContact={modalProps?.title} />;
      case 'AddIntegrationConfirm':
        return (
          <AddIntegrationConfirm
            nameOfClient={modalProps?.title}
            nameOfService={modalProps?.additionalText}
          />
        );
      case 'DeleteConfirmation':
        return (
          <DeleteConfirmation
            apiValue={modalProps?.apiValue}
            deletionType={modalProps?.title}
          />
        );
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      {isOpen && (
        <StyledWrapperDialog>
          <StyledDialog
            open={isOpen}
            onClose={handleCloseModal}
            status={modalProps?.status}
            isadmin={modalProps?.isAdmin}
            position={modalProps?.position}
            top={modalProps?.top}
            radius={modalProps?.radius}
            background={modalProps?.background}
            zindex={modalProps?.zIndex}
          >
            <StyledLine status={modalProps?.status} />
            {modalContent()}
          </StyledDialog>
        </StyledWrapperDialog>
      )}
    </>
  );
};

export default Modal;
