import instance from './api';

export const postGetUserOrganizations = async () => {
  const { data } = await instance.post('v1/organizations');

  return data;
};

export const postRetrieveClients = async (def: null) => {
  const { data } = await instance.post('v1/clients', { def: null });

  return data;
};
