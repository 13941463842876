import { useAppDispatch, useAppSelector } from 'hooks';
import {
  SectionTitle,
  WrapperGeneral,
  WrapperServices,
} from './SetUpPage.styled';
import { useEffect } from 'react';
import {
  retrieveClients,
  retrieveTools,
} from 'reduxStore/createProfile/createProfileOperations';
import {
  getClients,
  getTools,
} from 'reduxStore/createProfile/createProfileSelectors';
import { StepAction } from 'ui';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const SetUpPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clients = useAppSelector(getClients);
  const services = useAppSelector(getTools);

  const connectedServices = services.filter(
    service =>
      (service.intgrn !== null && service.intgrn.has_creds === true) ||
      (service.clients_count !== null && service.clients_count !== 0)
  );
  const disconnectedServices = services.filter(
    service =>
      !connectedServices.some(
        connectedService => connectedService.id === service.id
      )
  );

  useEffect(() => {
    dispatch(retrieveClients(null));
    dispatch(retrieveTools(null));
  }, [dispatch]);

  const handleDisconnectedServiceClick = () => {
    navigate(ROUTES.ADD_SERVICE);
  };

  return (
    <WrapperGeneral>
      <WrapperServices>
        {Array.isArray(connectedServices) &&
          connectedServices.map(connectedService => (
            <StepAction width="20vw" height="20vh">
              {connectedService.name} <br /> {connectedService.clients_count}/
              {clients.length} Clients
            </StepAction>
          ))}
      </WrapperServices>
      {disconnectedServices.length > 0 && (
        <>
          <SectionTitle>Also Available:</SectionTitle>
          <WrapperServices>
            {Array.isArray(disconnectedServices) &&
              disconnectedServices.map(disconnectedService => (
                <StepAction
                  width="20vw"
                  height="20vh"
                  isDisconnected="true"
                  onClick={handleDisconnectedServiceClick}
                >
                  {disconnectedService.name}
                </StepAction>
              ))}
          </WrapperServices>
        </>
      )}
    </WrapperGeneral>
  );
};

export default SetUpPage;
