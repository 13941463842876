import {
  ICreateOrganizationBody,
  IImportUsersBody,
  IUpdateOrganizationBody,
  ISetUpSelectedCloudsBody,
  ICreateContactBody,
  ICreateOrganizationV2Body,
  IReceiveToolOrgsBody,
  ICreateIntegrationBody,
  IDeleteIntgrnBody,
  IDeleteContactBody,
} from 'types/api.type';
import instance from './api';

export const postCreateOrganizationV1 = async (
  body: ICreateOrganizationBody
) => {
  const { data } = await instance.post('v1/create-org', body);

  return data;
};

export const postCreateOrganizationV2 = async (
  body: ICreateOrganizationV2Body
) => {
  const { data } = await instance.post('v2/create-org', body);

  return data;
};

export const postGetClouds = async (id: number | null) => {
  const { data } = await instance.post('v1/clouds', { org_id: id });

  return data;
};

export const postSetUpClouds = async (body: ISetUpSelectedCloudsBody) => {
  const { data } = await instance.post('v1/setup-cloud-regions', body);

  return data;
};

export const postUpdateOrganization = async (body: IUpdateOrganizationBody) => {
  const { data } = await instance.post('v1/update-org', body);
  return data;
};

export const postImportUsers = async (body: IImportUsersBody) => {
  const { data } = await instance.post('v1/import-users', body);

  return data;
};

export const postRetrieveContacts = async (id: number) => {
  const { data } = await instance.post('v1/contacts', { org_id: id });

  return data;
};

export const postRetrieveAssignableContacts = async (id: number) => {
  const { data } = await instance.post('v1/assignable-contacts', {
    org_id: id,
  });

  return data;
};

export const postCreateContact = async (body: ICreateContactBody) => {
  const { data } = await instance.post('v1/create-contact', body);

  return data;
};

export const postRetrieveRoles = async (id: number) => {
  const { data } = await instance.post('v1/roles', { org_id: id });

  return data;
};

export const postRetrieveClients = async (def: null) => {
  const { data } = await instance.post('v1/clients', { def: null });

  return data;
};

export const postRetrieveTools = async (def: null) => {
  const { data } = await instance.post('v1/tools', { def: null });

  return data;
};

export const postCheckAPICredentials = async (body: IReceiveToolOrgsBody) => {
  const { data } = await instance.post('v1/check-intgrn-auth', body);

  return data;
};

export const postCreateIntegration = async (body: ICreateIntegrationBody) => {
  const { data } = await instance.post('v1/create-intgrn', body);

  return data;
};

export const postRetrieveToolOrgs = async (id: number) => {
  const { data } = await instance.post('v1/tool-orgs', { tool_id: id });

  return data;
};

export const postDeleteIntegration = async (body: IDeleteIntgrnBody) => {
  const { data } = await instance.post('v1/delete-intgrn', body);

  return data;
};

export const postDeleteClient = async (id: number) => {
  const { data } = await instance.post('v1/remove-client', { id: id });

  return data;
};

export const postDeleteContact = async (body: IDeleteContactBody) => {
  const { data } = await instance.post('v1/remove-contact', body);

  return data;
};
