//import { useState } from 'react';
import {
  ActionMenuItem,
  ActionsDivider,
  ActionsWrapper,
  MenuProps,
  Selection,
} from './SelectActions.styled';
import { SelectChangeEvent } from '@mui/material';
import { ACTION_SELECTOR } from 'constants/selectActions';
import { ISelectItemProps } from 'types/selectItem.type';

interface ISelectAction {
  name: string;
  onAdd?: () => void;
  onDelete?: () => void;
}

const SelectAction = ({ name, onAdd, onDelete }: ISelectAction) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    if (value === 'Add') {
      onAdd && onAdd();
    } else if (value === 'Delete') {
      onDelete && onDelete();
    }
  };
  return (
    <ActionsWrapper>
      <Selection
        displayEmpty={true}
        renderValue={() => {
          return name;
        }}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {ACTION_SELECTOR.map(
          (
            variantItem: ISelectItemProps,
            index: number,
            array: ISelectItemProps[]
          ) => [
            <ActionMenuItem
              key={`${variantItem.title}-${index}`}
              value={variantItem.title}
            >
              <>
                {variantItem.title} {variantItem.icon}
              </>
            </ActionMenuItem>,
            index !== array.length - 1 && (
              <ActionsDivider key={`divider-${index}`} />
            ),
          ]
        )}
      </Selection>
    </ActionsWrapper>
  );
};

export default SelectAction;
