import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './routes.const';
import {
  SignOn,
  OrgProfile,
  AddTeamMember,
  AddClient,
  AddService,
  DeleteIntegration,
  DeleteClient,
  DeleteTeamMember,
} from 'views';
import {
  AuthLayoutPage,
  MainLayoutPage,
  CreateProfileLayoutPage,
  DashboardPage,
  TeamMembersPage,
  ClientsListPage,
  ServicesListPage,
  SetUpPage,
} from 'pages';
import { RedirectPage, AuthHandler } from 'components';
import ProtectedProfileRoute from './ProtectedProfileRoute/ProtectedProfileRoute';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import PublicRoute from './PublicRoute/PublicRoute';
import App from 'App';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <div>Not Found Page</div>,
    children: [
      {
        path: ROUTES.REDIRECT,
        element: <RedirectPage />,
      },
      {
        path: ROUTES.PROCESS_AUTH,
        element: <AuthHandler />,
      },
      {
        element: (
          <PublicRoute>
            <AuthLayoutPage />
          </PublicRoute>
        ),
        children: [{ path: ROUTES.SIGNIN, element: <SignOn /> }],
      },
      // --- create organization routes ---
      {
        element: (
          <ProtectedProfileRoute>
            <CreateProfileLayoutPage />
          </ProtectedProfileRoute>
        ),
        children: [
          { path: ROUTES.ORGANIZATION_PROFILE, element: <OrgProfile /> },
        ],
      },
      // --- dashboard routes ---
      {
        element: (
          <ProtectedRoute>
            <MainLayoutPage />
          </ProtectedRoute>
        ),
        children: [
          // --- DASHBOARD ---
          {
            path: ROUTES.DASHBOARD,
            element: <DashboardPage />,
          },
          // --- DASHBOARD ---
          {
            path: ROUTES.SET_UP,
            element: <SetUpPage />,
          },
          // --- add new team member ---
          {
            path: ROUTES.ADD_TEAM_MEMBER,
            element: <AddTeamMember />,
          },
          // --- add new client ---
          {
            path: ROUTES.ADD_CLIENT,
            element: <AddClient />,
          },
          // --- add new service ---
          {
            path: ROUTES.ADD_SERVICE,
            element: <AddService />,
          },
          // --- TEAM MEMBERS ---
          {
            path: ROUTES.TEAM_MEMBERS,
            element: <TeamMembersPage />,
          },
          // --- CLIENTS ---
          {
            path: ROUTES.CLIENTS,
            element: <ClientsListPage />,
          },
          // --- SERVICES ---
          {
            path: ROUTES.SERVICES,
            element: <ServicesListPage />,
          },
          // --- delete integration (about service) ---
          {
            path: ROUTES.DELETE_INTEGRATION,
            element: <DeleteIntegration />,
          },
          // --- delete client ---
          {
            path: ROUTES.DELETE_CLIENT,
            element: <DeleteClient />,
          },
          // --- delete client ---
          {
            path: ROUTES.DELETE_TEAM_MEMBER,
            element: <DeleteTeamMember />,
          },
        ],
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
