import { FlatButton } from 'ui';
import useAppDispatch from 'hooks/useAppDispatch';
import { closeModal } from 'reduxStore/modal/modalSlice';
import { WrapperConfirmation, MainText } from './AddIntegrationConfirm.styled';
import { Box } from '@mui/material';

interface IAddClientConfirmProps {
  nameOfClient: string | undefined;
  nameOfService: string | undefined;
}

const AddIntegrationConfirm = ({
  nameOfClient,
  nameOfService,
}: IAddClientConfirmProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeModal());
  };

  return (
    <WrapperConfirmation>
      <Box width="500px" height="96px">
        <MainText>
          {nameOfClient}
          <br /> has been connected to {nameOfService}
        </MainText>
      </Box>

      <FlatButton
        onClick={handleClick}
        variant="contained"
        width="134px"
        height="50px"
      >
        OK
      </FlatButton>
    </WrapperConfirmation>
  );
};
export default AddIntegrationConfirm;
