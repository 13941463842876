import { SelectActions, TableBasis } from 'ui';
import { Wrapper } from './ClientsListPage.styled';
import { CLIENTS_TABLE } from 'constants/tablesHead';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getClients } from 'reduxStore/createProfile/createProfileSelectors';
import { retrieveClients } from 'reduxStore/createProfile/createProfileOperations';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const ClientsListPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clients = useAppSelector(getClients);

  useEffect(() => {
    dispatch(retrieveClients(null));
  }, [dispatch]);

  const handleAddClient = () => {
    navigate(ROUTES.ADD_CLIENT);
  };
  const handleDeleteClient = () => {
    navigate(ROUTES.DELETE_CLIENT);
  };

  console.log(clients);

  const reformatedClients = Array.isArray(clients)
    ? clients.map(client => {
        return {
          name: client.name,
          dev_count: client.dev_count,
          admin_count: client.admin_count,
          alert_count: client.alert_count,
          tool_count: client.tool_count,
        };
      })
    : [];

  return (
    <Wrapper>
      <TableBasis
        tableData={reformatedClients}
        columns={CLIENTS_TABLE}
        title="Clients"
        icon="edit"
        action={
          <SelectActions
            name="Clients"
            onAdd={handleAddClient}
            onDelete={handleDeleteClient}
          />
        }
      ></TableBasis>
    </Wrapper>
  );
};

export default ClientsListPage;
