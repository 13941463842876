import { ITableColumn } from 'types';

export const TEAM_MEMBERS_TABLE: ITableColumn[] = [
  { label: 'First Name', key: 'first_name', disableSorting: false },
  { label: 'Last Name', key: 'last_name', disableSorting: false },
  { label: 'Phone Number', key: 'phone', disableSorting: false },
  { label: 'E-mail', key: 'email', disableSorting: false },
  { label: 'Role', key: 'role', disableSorting: false },
  // { label: 'Administrator', value: 'switch_admin', disableSorting: false },
];

export const CLIENTS_TABLE: ITableColumn[] = [
  { label: 'Client Name', key: 'name', disableSorting: false },
  { label: 'Tools', key: 'tool_count', disableSorting: false },
  { label: 'Devices', key: 'dev_count', disableSorting: false },
  { label: 'Admins', key: 'admin_count', disableSorting: false },
  { label: 'Alerts', key: 'alert_count', disableSorting: false },
];

export const SERVICES_TABLE: ITableColumn[] = [
  { label: 'Service Name', key: 'service_name', disableSorting: false },
  { label: 'Client Count', key: 'client_count', disableSorting: false },
  { label: 'Devices', key: 'devices', disableSorting: false },
  { label: 'Status', key: 'status', disableSorting: false },
  { label: 'Is Connected', key: 'is_connected', disableSorting: false },
];
