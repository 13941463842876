import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Title,
  WrapperAddMember,
  MenuItemText,
  MenuItemBox,
  Error,
} from './AddTeamMember.styled';
import { FlatButton } from 'ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getCreateProfileError,
  getRoles,
} from 'reduxStore/createProfile/createProfileSelectors';
import { useEffect } from 'react';
import {
  createContact,
  retrieveRoles,
} from 'reduxStore/createProfile/createProfileOperations';
import { getMainOrganizationID } from 'reduxStore/organization/organizationSelectors';
import { ICreateContactBody } from 'types/api.type';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  openModal,
  setContent,
  setModalProps,
} from 'reduxStore/modal/modalSlice';
import { useNavigate } from 'react-router';
import { ROUTES } from 'routes/routes.const';

const validationSchema = Yup.object({
  first_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('First name is required'),
  last_name: Yup.string()
    .min(2, 'Minimum 2 symbols are required')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role_id: Yup.string()
    .required('Role is required')
    .test('not-select', 'Role is required', value => value !== '0'),
});
const AddTeamMember = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const roles = useAppSelector(getRoles);
  const organizationId = useAppSelector(getMainOrganizationID);
  const error = useAppSelector(getCreateProfileError);

  useEffect(() => {
    dispatch(retrieveRoles(organizationId));
  }, [dispatch, organizationId]);

  const initialValues: ICreateContactBody = {
    org_id: organizationId,
    is_admin: true,
    first_name: '',
    last_name: '',
    email: '',
    phone: null,
    role_id: 0,
  };

  const handleSubmit = async (value: ICreateContactBody) => {
    try {
      await dispatch(createContact(value)).unwrap();
      navigate(ROUTES.TEAM_MEMBERS);
      dispatch(setModalProps({ radius: '18px', title: value.email }));
      dispatch(openModal());
      dispatch(setContent('AddTeamMemberConfirm'));
    } catch (error) {
      return;
    }
  };

  return (
    <WrapperAddMember>
      <Title>Add New Team Member</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Field
                  name="first_name"
                  as={TextField}
                  label="First Name"
                  variant="outlined"
                  placeholder="Enter First Name"
                  fullWidth
                  error={touched.first_name && Boolean(errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="last_name"
                  as={TextField}
                  label="Last Name"
                  variant="outlined"
                  placeholder="Enter Last Name"
                  fullWidth
                  error={touched.last_name && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={8}>
                <Field
                  name="email"
                  as={TextField}
                  label="Email"
                  variant="outlined"
                  placeholder="Enter Email"
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={8}>
                <FormControl fullWidth>
                  <MuiPhoneNumber
                    defaultCountry={'us'}
                    name="phone"
                    value={values.phone}
                    label="Phone Number"
                    variant="outlined"
                    placeholder="Enter Phone Number"
                    onChange={value => setFieldValue('phone', value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8}>
                <Field
                  name="role_id"
                  as={TextField}
                  id="role_id"
                  label="Choose Role"
                  variant="outlined"
                  select
                  fullWidth
                  error={touched.role_id && Boolean(errors.role_id)}
                  helperText={touched.role_id && errors.role_id}
                  InputLabelProps={{ htmlFor: 'role_id', shrink: true }}
                >
                  <MenuItem value={0} style={{ display: 'none' }}>
                    <MenuItemText>Select Role</MenuItemText>
                  </MenuItem>
                  {Array.isArray(roles) &&
                    roles.map((role, index) => (
                      <MenuItem key={index} value={role.id} style={MenuItemBox}>
                        <MenuItemText>{role.name}</MenuItemText>
                      </MenuItem>
                    ))}
                </Field>
              </Grid>

              <Grid item xs={8} marginTop="50px">
                <FlatButton
                  width="200px"
                  type="submit"
                  variant="contained"
                  isIcon="true"
                >
                  Add Team Member
                </FlatButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {!!error && <Error>{error}</Error>}
    </WrapperAddMember>
  );
};

export default AddTeamMember;
