import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export const ACTION_SELECTOR = [
  {
    title: 'Add',
    icon: <AddIcon />,
  },
  {
    title: 'Delete',
    icon: <DeleteForeverOutlinedIcon color="error" />,
  },
];
